import React from "react";
import { Grid } from "~/components/grid";

import { LogoList, Logo } from "./as-seen-on.style";

const LOGOS = [
  {
    title: "Good Morning America on ABC",
    src: "logos/gma-abc-logo-gold_tufxvn",
  },
  {
    title: "Cheddar",
    src: "logos/cheddar-logo-gold_d5g0t6",
  },
  {
    title: "Entertainment Weekly",
    src: "logos/entertainment-weekly-logo-gold_qeryol",
  },
  {
    title: "The Skimm",
    src: "logos/theskimm-logo-gold_qfnqmz",
  },
  {
    title: "This Is It",
    src: "logos/this-is-it-gold_gtulkb",
  },
  {
    title: "NBC Charlotte",
    src: "logos/nbc_charlotte-gold_gc85cr",
  },
];

export const AsSeenOn = () => (
  <Grid>
    <LogoList>
      {LOGOS.map(logo => <Logo key={logo.src} {...logo} />)}
    </LogoList>
  </Grid>
)
