import React from "react";

import { Main } from "~/layouts";
import { useIntersectionObserver } from "~/hooks";
import { getImageSrc } from "~/utils/cloudinary";
import { Seo } from "~/components/seo";
import { HeroTitle } from "~/components/hero";
import { HeroSubheadline, Subheadline } from "~/components/typography";
import { AsSeenOn } from "~/components/as-seen-on";
import { Footer } from "~/components/footer";
import {
  MarketingBlock,
  MarketingCopy,
  MarketingImageFull,
} from "~/components/marketing";

const StoryPage = () => {
  const [intersectionRef, isInView] = useIntersectionObserver();
  const julieImagePath = getImageSrc("julie-web-headshot-3_zmqwfi", "w_1000");

  return (
    <Main fullView>
      <Seo title="Our Story" />
      <HeroTitle
        title="Our Story"
        src="thegoodsnail2-small_mk05ax"
      />
      <MarketingBlock style={{ textAlign: "right" }}>
        <HeroSubheadline>"USPS is my bae"</HeroSubheadline>
        <Subheadline style={{ marginTop: 0 }}>—Julie</Subheadline>
      </MarketingBlock>
      <MarketingBlock>
        <MarketingCopy>I've had a love for cards since I was a child. They're little pieces of art with heartfelt messages cherished for a lifetime. To be part of someone's memories like that is priceless.</MarketingCopy>
        <MarketingCopy>As an avid collector myself, I realized that many people love giving and receiving heartfelt or fun cards in the mail. However, in this crazy day and age, it can be tough to dedicate time to finding just the right card, coming up with a meaningful message to write in it, and getting the darn thing out to the mail on time! I want to give people the chance to send cards without all the hassle while simultaneously supporting a range of independent artists.</MarketingCopy>
      </MarketingBlock>

      <MarketingImageFull
        ref={intersectionRef}
        $imagePath={isInView && julieImagePath}
      />

      <MarketingBlock>
        <MarketingCopy>The designers are intrinsic to our community. When we purchase cards we don't typically think about the designer behind the card or their life experiences that helped create the card you picked. Ultimately, you connect on a human level if you have chosen their art and message.</MarketingCopy>
        <MarketingCopy>Our goal is to spotlight each designer's work not only on our digital space but the product itself. We believe in our card community as much as snail mail because it wouldn't be possible to share your feelings in a card without the designers. We’re committed to providing a platform to showcase a growing curation of talented designers and to share our love of connection through cards.</MarketingCopy>
        <MarketingCopy style={{ textAlign: "right" }}>
          —Julie Reif<br />
          <i>Founder, The Good Snail</i>
        </MarketingCopy>
      </MarketingBlock>
      <AsSeenOn />
      <Footer />
    </Main>
  );
}

export default StoryPage;
