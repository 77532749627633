import styled from "styled-components";

import { getImageSrc } from "~/utils/cloudinary";
import { IS_SMALL } from "~/utils/media";

export const LogoList = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0 auto 4rem;
  padding: 4rem 2rem;
  max-width: 900px;

  ${IS_SMALL} {
    flex-wrap: wrap;
    max-width: 540px;
  }
`;

export const Logo = styled.img.attrs(({ title, src }) => ({
  src: getImageSrc(src),
  title,
  alt: "title",
}))`
  display: block;
  width: 15%;
  max-width: 120px;

  ${IS_SMALL} {
    width: 33%;
    max-width: 180px;
  }
`;
